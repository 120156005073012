import { create } from "apisauce";
import { store } from "reducers/Store";

const appApi = create({
  baseURL: "https://api.yekpay.com/api/",
  headers: { "Content-Type": "application/json" },
});

appApi.addAsyncRequestTransform((request) => async () => {
  const authToken = store.getState().userReducer.token;
  if (!authToken) return;
  request.headers["Authorization"] = "Bearer " + authToken;
});

export default appApi;
