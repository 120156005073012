import React from "react";
import languages from "../../languages";
// reactstrap components
import { Row, Col } from "reactstrap";
import * as LottiePlayer from "@lottiefiles/lottie-player";

const Loading = (props) => {
  const contentClassName = props.isFullScreen ? "text-center full-screen-content" : "text-center";
  return (
    <div className="content">
      <Row className={contentClassName}>
        <Col className="align-self-center ">
          <lottie-player
            autoplay
            loop={true}
            mode="normal"
            speed={1.5}
            src="https://lottie.host/8692e6a5-6fd3-4a57-b190-2949b71b0e17/LGphLwTXRQ.json"
            style={{ width: "350px", margin: 'auto' }}
          ></lottie-player>
          {/* <span className="yp-loading">
            <img src={require("../../assets/img/loading.svg")} alt="" />
            {languages["en"].loading}
          </span> */}
        </Col>
      </Row>
    </div>
  );
};

export default Loading;
