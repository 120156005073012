import axios from "axios";
import languages from "./languages";
import { setLink } from "./Values";
import store from "reducers/Store";
import { showModal } from "actions/index";

export const SITE_URL = "https://live.finotransfer.com/";
export const SITE_NAME = SITE_URL + "api/";

export const ShowModal = ({ message, data }, statusCode, errors) => {
  if (statusCode === 401) {
    //  thisProps.history.push(setLink("/auth/login", lang));
    window.location.assign(setLink("/auth/login", "en"));
    store.store.dispatch(
      showModal("QuestionDialog", {
        status: "danger",
        description: languages["en"].errorlogin,
      })
    );
  }
  if (statusCode === 400) {
    store.store.dispatch(
      showModal("QuestionDialog", {
        status: "danger",
        description: data ? data : "Unknown error",
      })
    );
  }
  if (statusCode === 500) {
    store.store.dispatch(
      showModal("QuestionDialog", {
        status: "danger",
        description: languages["en"].errorDescription,
      })
    );
    console.log(errors, "error!!!!");
  }
};
const defaultParams = {
  query: null,
  post: null,
  token: null,
  lang: "en",
  showModal: undefined,
  onAnyFailed: () => {},
};

const execute = (method, url, onSuccess, params = defaultParams, headerOptions) => {
  const newParams = { ...defaultParams, ...params };
  request(method, url, onSuccess, newParams, headerOptions);
};

const request = (method, url, onSuccess, params = defaultParams, headerOptions) => {
  axios({
    method: method,
    url: SITE_NAME + url,
    params: params.query,
    data: params.post,
    headers: {
      "Content-Type": headerOptions && headerOptions['Content-Type'] ? headerOptions['Content-Type'] : "application/json",
      ...(params.token ? { Authorization: "Bearer " + params.token } : {}),
    },
  })
    .then((response) => {
      onSuccess(response.data.data);
    })
    .catch((errors) => {
      try {
        console.log("catch error", errors.response);
        let statusCode = errors.response?.status;
        let errorData = errors.response?.data;
        ShowModal(errorData, statusCode, errors.response);
      } catch (error) {
        console.log("Unknown Error", error);
      }

      params.onAnyFailed(errors);
    });
};

export default execute;
