import { ADDCART } from '../actions';

const INITIAL_STATE = {
    cart : []
}
const CartReducer = (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case ADDCART:
            return {...state,
                cart : action.item
            };
        default:
            return {...state}
    }
};

export default CartReducer;
