import {
  INIT_USER,
  UPDATE_USER_DATA,
  UPDATE_SEEN_ANNOUNCEMENTS,
  LOGOUT_USER,
  GET_SEEN_ANNOUNCEMENTS,
  UPDATE_UNSEEN_ANNOUNCEMENTS,
} from "../actions";
import { PURGE } from "redux-persist";

export const UserInit = {
  id: null,
  first_name: null,
  last_name: null,
  email: null,
  mobile: null,
  phone: null,
  image: null,
  token: null,
  plan_id: 2,
  email_status: "Inactive",
  mobile_status: "Inactive",
  status: "Inactive",
  otp: "Inactive",
  profile_picture: null,
  identity_attachment: null,
  sms_notification: "Inactive",
  email_notification: "Inactive",
  ref_currency_id: null,
  timezone_id: null,
  renew: "Inactive",
  created_at: "2000-01-01 12:00:00",
  updated_at: "2000-01-01 12:00:01",
  username: "",
  strength: 0,
  promotion_code: "",
  national_code: "",
  unseenAnnouncements: [],
  seenAnnouncements: [],
  changed_user_at: null,
};

const userReducer = (state = UserInit, action) => {
  switch (action.type) {
    case INIT_USER:
      return { ...state, ...action.user };
    case LOGOUT_USER:
      return { ...state, ...UserInit };
    case UPDATE_USER_DATA:
      return { ...state, ...action.payload };
    case UPDATE_UNSEEN_ANNOUNCEMENTS:
      return {
        ...state,
        unseenAnnouncements: action.payload,
      };
    case GET_SEEN_ANNOUNCEMENTS:
      let an = [];
      if (action.payload.page === 1) an = action.payload.seenAnnouncements;
      else
        an = state.seenAnnouncements.concat(action.payload.seenAnnouncements);
      return {
        ...state,
        seenAnnouncements: an,
      };
    case UPDATE_SEEN_ANNOUNCEMENTS:
      let seenAn = [...state.seenAnnouncements];
      let newSeenAn = action.payload;
      let lastSeenAn = [];
      if (newSeenAn.length >= 1) {
        lastSeenAn.push(newSeenAn[0]);
        seenAn.pop();
      }
      let conactedSeenAnnouncements = lastSeenAn.concat(seenAn);
      return {
        ...state,
        seenAnnouncements: conactedSeenAnnouncements,
      };
    case PURGE:
      return UserInit;
    default:
      return state;
  }
};

export default userReducer;
