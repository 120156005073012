/**
 * Created by morteza on 5/14/17.
 */
import {TICKET_COUNT} from '../actions';

const ticketCountReducer = (state = 0, action) => {
    switch (action.type) {
        case TICKET_COUNT:
            return action.count;
        default:
            return state
    }
};

export default ticketCountReducer;