import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./reducers/Store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { appColors } from "Values";

Sentry.init({
  dsn: "https://1b21da1414ab49f597d8b3d47863ce99@o495801.ingest.sentry.io/5569183",
});

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: appColors.primary,
    },
    secondary: {
      main: appColors.secondary,
    }
  },
});

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>

      <BrowserRouter>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={hist}>
            <App store={store} history={hist} />
          </Router>
        </PersistGate>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
